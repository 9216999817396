
import Promessas from './1_Promessa';
import Beneficios from './2_Beneficios';
import Estrategias from './3_Estrategias';
import Local from './3_local';
import Infraestrtura from './3_Infraestrutura';
import Valores from './3_Valores'
import Rodape from './9_Rodape'
import logo from '../assets/logofolha.jpg'
import Leadmorno from './3_Leadmorno';


import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Imoveis from './3_Imoveis';
import Apto from './4_apto57';
import Casa100 from './4_Casa100';
import Casa130 from './4_Casa130';
import Casa200 from './4_Casa200';

function Home() {
  return (
  <>
      <header>
          <Promessas/>
      </header>
      <body className="w-full h-full bg-primary-gray bg-no-repeat bg-center bg-cover">
            <Beneficios/>
            <Local/>
            <Estrategias/>
            <Imoveis/>
            <Apto/>
            <Casa100/>
            <Casa130/>
            <Casa200/>
    
            < Valores/>
            <Leadmorno/>
           
            <Rodape/>
            <FloatingWhatsApp
        id="zap"    
        phoneNumber="5573999407548"
        accountName="Duas Barras"
        allowEsc
        allowClickAway
        notification
        notificationSound
        statusMessage = "Normalmente respondemos dentro de 20 minutos"
        chatMessage= "Olá!🤝 Como podemos ajudar?"
        avatar={logo}
      />
       </body>
       <futter>
       </futter>
     
   
  </>
  );
}

export default Home;
